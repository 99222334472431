const purchaseConfig = {
    // メール配信登録での付与ポイント
    campaignNewsSubscriptionAddingPoint: 200,

    couponCodeRateType: {
        shipping_fee: "1",
        item_price: "2",
        point: "3",
    },

    orderStatus: {
        'S50': '発送済み',
        'S30': '発送準備中',
        'S70': 'キャンセル済み',
    },

    digitalProductType: {
        'event': 'イベントチケット',
        'digital': 'デジタルコンテンツ',
    },

    shippingType: {
        letterPack: 'YL',
        yuMail: 'YM',
        yuPack: 'YP',
        yuPacket: 'YC',
        wholesale: 'VO',
        yamatoCompact: 'KY',
    },

    shippingCompany: {
        YU: '日本郵便',
        YM: 'ヤマト運輸',
    },

    taxRate: 10,

    // セッションストレージのキー
    sessionStorageKey: {
        usePoint: 'usePoint',
        checkedUsePoint: 'checkedUsePoint',
        purchaseCouponCode: 'purchaseCouponCode',
        purchaseBirthDate: 'purchaseBirthDate',
        purchaseAge: 'purchaseAge',
    },
}


export default purchaseConfig;