/**
 * cross-envを使い環境ごとに設定ファイルを切り替える
 * NODE_ENVはpackage.jsonの実行コマンドの引数となる
 * @type {string|string}
 */

const environment = process.env.NODE_ENV || 'development';
import devEnvironmentConfig from './config/development.js';
import prodEnvironmentConfig from './config/production.js';
const environmentConfig  =  environment == 'development' ? devEnvironmentConfig : prodEnvironmentConfig;
import searchConfig from './config/search.js';
import sellConfig  from './config/sell.js';
import purchaseConfig from './config/purchase.js';
import recaptchaConfig from './config/recaptcha.js';

const vueConfig = {
    mode: environment,
    'opnebd_baseurl':'https://cover.openbd.jp/',
    'estimate_url' : environmentConfig.estimate_pricing_url,
    'cdn_base_url':'https://wcdn.valuebooks.jp',
    'cdn_url':'https://wcdn.valuebooks.jp/ws/site',
    'line_url': 'https://s.valuebks.info/3gbM01w',
    'x_url': 'https://x.com/info_ValueBooks',
    'instagram_url': 'https://www.instagram.com/valuebooks_press/',
    'vaboo_url': 'https://www.vaboo.jp/',
    'charibon_url': 'https://www.charibon.jp/',
    // cssブレークポイント
    'breakpointMd': 896,
    // 指定本棚スキャン用の定義リスト
    'topScanSamples': {
        'akira': {
            'character': '歴史ものが気になる',
            'name': 'アキラ',
            'age': 65,
            'type': 'akira',
            'title': '10年以上前の本や文庫本が多く、査定額は低め。',
            'description': '歴史好きのアキラさん。若いころに読んだ本や、時代劇、古典などが多く並びます。思い入れのある本たちですが、買取額はどうしても低くなってしまいがち。買取に出す場合は、文庫本は避け、比較的新しい単行本を送るのが良さそうです。古い本は、街の古書店へ持ち込むといいお値段をつけてくれる可能性も。',
            'file_id': environmentConfig.SCAN_FILE_ID_KEY1,
            'meta': {
                'title': 'おためし査定 ・歴史本の買取額 | 古本買取のバリューブックス',
                'description': '本棚スキャンで自宅の本棚をスマホで簡単査定！歴史好きの本棚の査定結果。お持ちの本の市場価格が今すぐわかります。'
            },
            'images': {
                'cdn': {
                  'pc': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/pc_scan-photo_akira.jpg',
                  'sp': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/sp_scan-photo_akira.jpg',
                },
                'local': {
                  'pc': '/assets/images/index/scan_sample/pc_scan-photo_akira.jpg',
                  'sp': '/assets/images/index/scan_sample/sp_scan-photo_akira.jpg',
                }
            }
        },
        'sae': {
            'character': '料理と育児に関心',
            'name': 'サエ',
            'age': 38,
            'type': 'sae',
            'title': '新刊や定価の高い本も多く、査定額はやや高め。',
            'description': '小さなお子さんと暮らすサエさん。育児本や子どものための絵本、料理本や食にまつわるエッセイなどが多めです。本を買取に出すなら、何度も読み込んだ状態の悪いものは避け、比較的状態のいいものを送ると高価査定が期待できそうです。',
            'file_id': environmentConfig.SCAN_FILE_ID_KEY2,
            'meta': {
                'title': 'おためし査定・料理本の買取額 | 古本買取のバリューブックス',
                'description': '本棚スキャンで自宅の本棚をスマホで簡単査定！料理本や育児書が並ぶ本棚の査定結果。お持ちの本の市場価格が今すぐわかります。'
            },
            'images': {
                'cdn': {
                  'pc': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/pc_scan-photo_sae.jpg',
                  'sp': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/sp_scan-photo_sae.jpg',
                },
                'local': {
                  'pc': '/assets/images/index/scan_sample/pc_scan-photo_sae.jpg',
                  'sp': '/assets/images/index/scan_sample/sp_scan-photo_sae.jpg',
                }
            }
        },
        'takuya': {
            'character': 'サブカルチャーが好き',
            'name': 'タクヤ',
            'age': 22,
            'type': 'takuya',
            'title': '古くても市場価値の高い本は、高価査定の可能性大！',
            'description': 'サブカルチャーが好きなタクヤさん。アートや写真、哲学にまつわる本など、専門的なものが多いので、査定額は高くなりそうです！ただ、背表紙のタイトルを読み込む「本棚スキャン」は、漫画の検索がまだまだ苦手（ごめんなさい！）。漫画の買取価格を調べる場合は、ページ上部の「キーワード検索」からお調べください。また、「コミック名 セット」で調べると、セット価格が表示されます。査定額アップにつながるので、コミックは揃った状態で送るのがおすすめ！。',
            'file_id': environmentConfig.SCAN_FILE_ID_KEY3,
            'meta': {
                'title': 'おためし査定・サブカル本の買取額 | 古本買取のバリューブックス',
                'description': '本棚スキャンで自宅の本棚をスマホで簡単査定！サブカルチャー好きの本棚の査定結果。お持ちの本の市場価格が今すぐわかります。'
            },
            'images': {
                'cdn': {
                  'pc': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/pc_scan-photo_takuya.jpg',
                  'sp': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/sp_scan-photo_takuya.jpg',
                },
                'local': {
                  'pc': '/assets/images/index/scan_sample/pc_scan-photo_takuya.jpg',
                  'sp': '/assets/images/index/scan_sample/sp_scan-photo_takuya.jpg',
                }
            }
        },
        'taiki': {
            'character': '勉強熱心なビジネスマン',
            'name': 'タイキ',
            'age': 35,
            'type': 'taiki',
            'title': '一部に人気商品が混ざり、査定額はやや高め。',
            'description': 'ビジネスマンのタイキさん。かつてのベストセラーから最新の話題作まで、新旧のビジネス書が詰まった本棚です。ビジネス本は人気ジャンル、査定額も高めです！ポイントは、今が旬の話題作は読み終えたら早めに買取に出すこと。市場価値が下がらないうちに買取に出すことで、さらなる高価査定につながります。',
            'file_id': environmentConfig.SCAN_FILE_ID_KEY4,
            'meta': {
                'title': 'おためし査定・ビジネス本の買取額 | 古本買取のバリューブックス',
                'description': '本棚スキャンで自宅の本棚をスマホで簡単査定！ビジネス本が並ぶ本棚の査定結果。お持ちの本の市場価格が今すぐわかります。'
            },
            'images': {
                'cdn': {
                  'pc': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/pc_scan-photo_taiki.jpg',
                  'sp': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/sp_scan-photo_taiki.jpg',
                },
                'local': {
                  'pc': '/assets/images/index/scan_sample/pc_scan-photo_taiki.jpg',
                  'sp': '/assets/images/index/scan_sample/sp_scan-photo_taiki.jpg',
                }
            }
        },
        'arisa': {
            'character': '趣味はひとり旅',
            'name': 'アリサ',
            'age': 26,
            'type': 'arisa',
            'title': '新しい本や根強く人気な本が多く、査定額はやや高め。',
            'description': '旅好きのアリサさん。本棚にも、旅にまつわるエッセイが並んでいます。根強い人気作が多く、査定価格も高めです。市場価値の下がりにくい本が多いので、日焼けや汚れに気をつけ、状態良く保管しておくことで、手放す時も全体的に高い査定額が期待できそうです。',
            'file_id': environmentConfig.SCAN_FILE_ID_KEY5,
            'meta': {
                'title': 'おためし査定・旅本の買取額 | 古本買取のバリューブックス',
                'description': 'ご自宅まで集荷に伺うので、本は詰めておくだけ。キャンセルも手軽にできるので、安心してご利用ください。'
            },
            'images': {
                'cdn': {
                  'pc': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/pc_scan-photo_arisa.jpg',
                  'sp': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/sp_scan-photo_arisa.jpg',
                },
                'local': {
                  'pc': '/assets/images/index/scan_sample/pc_scan-photo_arisa.jpg',
                  'sp': '/assets/images/index/scan_sample/sp_scan-photo_arisa.jpg',
                }
            }
        },
        'kohei': {
            'character': '愛読書はノンフィクション',
            'name': 'コウヘイ',
            'age': 31,
            'type': 'kohei',
            'title': '新しい本が少なく、査定額はやや低め。',
            'description': '学問に関する本や、実際の人物の評伝など、ノンフィクション系をよく読むコウヘイさん。全体の査定額はやや低めですが、 需要が見込める、平均よりぐっと高めの単価の本も何冊かありました。発売から1年以内の本は高価買取につながりやすいので、定期的に本棚整理をおこなうのがおすすめです。',
            'file_id': environmentConfig.SCAN_FILE_ID_KEY6,
            'meta': {
                'title': 'おためし査定・ノンフィクションの買取額 | 古本買取のバリューブックス',
                'description': '本棚スキャンで自宅の本棚をスマホで簡単査定！ノンフィクション系の本が並ぶ査定結果。お持ちの本の市場価格が今すぐわかります。'
            },
            'images': {
                'cdn': {
                  'pc': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/pc_scan-photo_kohei.jpg',
                  'sp': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/sp_scan-photo_kohei.jpg',
                },
                'local': {
                  'pc': '/assets/images/index/scan_sample/pc_scan-photo_kohei.jpg',
                  'sp': '/assets/images/index/scan_sample/sp_scan-photo_kohei.jpg',
                }
            }
        },
        'koji': {
            'character': '現代文学にハマる',
            'name': 'コウジ',
            'age': 43,
            'type': 'koji',
            'title': '少し前のベストセラーは値段がつきにくく、査定額はやや低め。',
            'description': '現代文学を好むコウジさん。新し目の本は買取価格が高くなりやすいですが、人気作家の作品やベストセラーとなった小説は、出回る本の量が多いため市場価値が下がりがちなのが悩みどころ。定期的に本棚整理をおこない、話題性の高い本は、読み終えたら早めに買取に出すことがおすすめです。',
            'file_id': environmentConfig.SCAN_FILE_ID_KEY7,
            'meta': {
                'title': 'おためし査定・現代文学の買取額 | 古本買取のバリューブックス',
                'description': '本棚スキャンで自宅の本棚をスマホで簡単査定！現代文学好きの本棚の査定結果。お持ちの本の市場価格が今すぐわかります。'
            },
            'images': {
                'cdn': {
                  'pc': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/pc_scan-photo_koji.jpg',
                  'sp': 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/scan_sample/sp_scan-photo_koji.jpg',
                },
                'local': {
                  'pc': '/assets/images/index/scan_sample/pc_scan-photo_koji.jpg',
                  'sp': '/assets/images/index/scan_sample/sp_scan-photo_koji.jpg',
                }
            }
        },
    },
    // ポイント履歴1ページあたりの、表示件数
    pointHistoriesPerPage: 20,
    // カート追加時のトーストメッセージ
    addCartToastMassageList: [
        {seq: 0, msg: "カートに追加しました" + "<a style='color: white; text-decoration: underline' href=\"/cart/list\">カートを見る</a>", status:"success", autoClose:true},
        {seq: 1, msg: "他のお客様が購入されたためカートに追加できませんでした。", status:"warn", autoClose:false},
        {seq: 2, msg: "申し訳ありません。システムエラーによりカートに追加できませんでした。再度お試しください。", status:"warn", autoClose:false}
    ],
    // 書誌詳細のレコメンド表示数 最大120
    recommendCount: 90,
    // 買取強化中を適用する基準額
    strengthenBasePrice: 300,
    search: searchConfig,
    // 発送の目安
    deliveryIndicationFrom : 1,
    deliveryIndicationTo: 3,
    // 買取
    sell: sellConfig,
    // 販売
    purchase: purchaseConfig,

    // reCaptcha
    recaptchaSiteKey: environmentConfig.recaptcha_site_key,
    recaptcha: recaptchaConfig,

    // コンディションコード
    conditionCode: {
        NEW: 'N10',
        LIKENEW: 'U10',
        VERYGOOD: 'U20',
        GOOD: 'U30',
        ACCEPTABLE: 'U40',
    },

    // 通知バーを表示しない画面
    ignoreNotificationList: [
        '/sell/offer-with-registration',
        '/sell/offer-with-registration/completed',
    ],
    resaleCampaign: [
        {
            // 販売キャンペーンの開始日時
            startDate: '2024-12-01T00:00:00+09:00',
            // 販売キャンペーンの終了日時
            endDate: '2024-12-31T23:59:59+09:00',
            message: '12月はブックギフト月間',
            image: '/assets/images/bookgift/bookgift-banner.jpg',
            link: '/bookgift-present/',
        },
        {
            // 販売キャンペーンの開始日時
            startDate: '2025-04-01T00:00:00+09:00',
            // 販売キャンペーンの終了日時
            endDate: '2025-04-20T23:59:59+09:00',
            message: '購入金額の10％ポイント還元',
            image: '/assets/images/campaign/campaign-sell-banner-250401.png',
            link: '/search?conditions_stock=2',
        },
    ],
    siteCode: {
        valuebooks: 'MA1',
        vaboo: 'VA1',
        charibon: 'CH1'
    },
    buyCampaignMessageDisplay: {
        // 販売キャンペーンの開始日時
        startDate: '2022-12-01T00:00:00+09:00',
        // 販売キャンペーンの終了日時
        endDate: '2022-12-31T23:59:59+09:00',
        message: "<span style='color:#dd0022;display:block;text-align:center;font-weight:bold;'>"+"12/31まで！<br /><nobr>＼ソクフリ選ぶと買取金額500円UP！／</nobr>",
    },
    couponKeyForCookies: {
        buy: {
            urlParamsName: 'couponcode',
            cookieName: 'coupon_code'
        },
        purchase: {
            urlParamsName: 'buycoupon',
            cookieName: 'buy_coupon_code'
        },
    },
    hideDefaultCampaignBanner: {// LPでデフォルトのキャンペーンバナーを非表示にする期間
        startDate: '2023-06-01 00:00:00',
        extendedTermStartDate: '2023-07-01 00:00:00',
        endDate: '2023-07-05 23:59:59',
    },
}

export default vueConfig