const sellConfig = {
    maxNumberOfPackages: 5,
    maxNumberOfPackagesForVaboo: 3,
    maxNumberOfPackagesForCharibon: 5,
    maxNumberOfPackagesForCharibonVbOffice: 20,
    VbOfficeCodeCharibon: 'r9rkwxxdt6tqwq6zp7rm',
    shippingFeePerPackage: 500,
    pickupRequestCutOffTimeMinusOffsetInHours: -1,
    estimatedPriceIncreasedBySokufuriApplication: 100,
    identitySubmissionMethod: [
        {id: 1, label: '確認した'},
        {id: 2, label: '本人確認書類を同梱する'},
    ],
    buybackCodeMaxLength: 8,
    defaultCouponCode: {
        LP: "ADD22LPS",
    },
    sokufuriApplication: [
        {id: 1, label: 'する'},
        {id: 2, label: 'しない'}
    ],
    anniversaryCampaign: {
        title: '本を送って福袋がもらえる！「新春キャンペーン」',
        url: '/otoshidama',
        description: '「ソクフリ」をご選択いただくと、査定金額10%UP！さらに査定金額2,000円以上の方の中から、抽選で801名様に福袋をプレゼント！',
    },
    charibonTerms: {
        default: 'https://www.charibon.jp/rules',
        coupons: [
            {code: "KITAHANADA", url: 'https://www.charibon.jp/rules/muji001/'},
            {code: "AEONTOWNASAHI", url: 'https://www.charibon.jp/rules/muji002/'},
            {code: "HIROSHIMAAP", url: 'https://www.charibon.jp/rules/muji003/'},
            {code: "ITABASHIMINAMI22", url: 'https://www.charibon.jp/rules/muji004/'},
            {code: "KASUGAI", url: 'https://www.charibon.jp/rules/muji005/'},
            {code: "AZUMINOHOTAKA", url: 'https://www.charibon.jp/rules/muji006/'},
            {code: "TARIAKE", url: 'https://www.charibon.jp/rules/muji007/'},
            {code: "KYOTOYAMASHINA", url: 'https://www.charibon.jp/rules/muji008/'},
        ]
    }
}

export default sellConfig;
