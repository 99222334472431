/**
 * 本番環境で使用する環境変数
 * npm run production実行環境で使用される定義
 */
const environmentConfig = {
    // 指定本棚スキャン用のスキャン結果DBキー
    'SCAN_FILE_ID_KEY1': '561e44e4-3307-419e-8390-d02a0b19b3a1_20210122103046.jpg',
    'SCAN_FILE_ID_KEY2': 'fa5bdccf-055a-47dd-9f4b-066fa4a3211b_20210122114452.jpg',
    'SCAN_FILE_ID_KEY3': 'fa9a4c0a-249b-4a19-916c-0cd9cacd107d_20210122110052.jpg',
    'SCAN_FILE_ID_KEY4': 'ee89557f-e059-4a70-94e7-af4810ed2969_20210122114817.jpg',
    'SCAN_FILE_ID_KEY5': '51d26d32-38f1-4be4-afd4-84f545e8269b_20210122105547.jpg',
    'SCAN_FILE_ID_KEY6': '100d2c65-169a-4ee9-9866-6aadcc4c5493_20210122105842.jpg',
    'SCAN_FILE_ID_KEY7': '8c183499-6be3-45e6-97fb-15ff1c8b3653_20210122114322.jpg',
    'estimate_pricing_url':'https://estimate.valuebooks.jp/pricing',
    // recaptchaのsiteKey設定
    'recaptcha_site_key': '6Ldi9bAlAAAAAArbsf52lzZZboI23GalUOHq406V',
    // 閲覧履歴の表示件数
    'max_browsing_history_display_count':20,
}

export default environmentConfig