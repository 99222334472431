const searchConfig = {
    MIN_RATE: 0,
    MAX_RATE: 100,
    NO_SPECIFY_DISPLAY_NAME: '指定なし',
    MIN_NO_SPECIFIED_VALUE: 0,
    MAX_NO_SPECIFIED_VALUE: -1,
    SEARCH_ITEMS_PER_PAGE: 56,
    TAG_SEARCH_ITEMS_PER_PAGE: 28,
    TITLE_SEARCH_ITEMS_PER_PAGE: 1,
    TREE: {
        a: 'ア', i: 'イ', u: 'ウ', e: 'エ', o: 'オ',
        k: {
            a: 'カ', i: 'キ', u: 'ク', e: 'ケ', o: 'コ',
            y: { a: 'キャ', i: 'キィ', u: 'キュ', e: 'キェ', o: 'キョ' },
        },
        s: {
            a: 'サ', i: 'シ', u: 'ス', e: 'セ', o: 'ソ',
            h: { a: 'シャ', i: 'シ', u: 'シュ', e: 'シェ', o: 'ショ' },
            y: { a: 'キャ', i: 'キィ', u: 'キュ', e: 'キェ', o: 'キョ' },
        },
        t: {
            a: 'タ', i: 'チ', u: 'ツ', e: 'テ', o: 'ト',
            h: { a: 'テャ', i: 'ティ', u: 'テュ', e: 'テェ', o: 'テョ' },
            y: { a: 'チャ', i: 'チィ', u: 'チュ', e: 'チェ', o: 'チョ' },
            s: { a: 'ツァ', i: 'ツィ', u: 'ツ', e: 'ツェ', o: 'ツォ' },
        },
        c: {
            a: 'カ', i: 'シ', u: 'ク', e: 'セ', o: 'コ',
            h: { a: 'チャ', i: 'チ', u: 'チュ', e: 'チェ', o: 'チョ' },
            y: { a: 'チャ', i: 'チィ', u: 'チュ', e: 'チェ', o: 'チョ' },
        },
        q: {
            a: 'クァ', i: 'クィ', u: 'ク', e: 'クェ', o: 'クォ',
        },
        n: {
            a: 'ナ', i: 'ニ', u: 'ヌ', e: 'ネ', o: 'ノ', n: 'ン',
            y: { a: 'ニャ', i: 'ニィ', u: 'ニュ', e: 'ニェ', o: 'ニョ' },
        },
        h: {
            a: 'ハ', i: 'ヒ', u: 'フ', e: 'ヘ', o: 'ホ',
            y: { a: 'ヒャ', i: 'ヒィ', u: 'ヒュ', e: 'ヒェ', o: 'ヒョ' },
        },
        f: {
            a: 'ファ', i: 'フィ', u: 'フ', e: 'フェ', o: 'フォ',
            y: { a: 'フャ', u: 'フュ', o: 'フョ' },
        },
        m: {
            a: 'マ', i: 'ミ', u: 'ム', e: 'メ', o: 'モ',
            y: { a: 'ミャ', i: 'ミィ', u: 'ミュ', e: 'ミェ', o: 'ミョ' },
        },
        y: { a: 'ヤ', i: 'イ', u: 'ユ', e: 'イェ', o: 'ヨ' },
        r: {
            a: 'ラ', i: 'リ', u: 'ル', e: 'レ', o: 'ロ',
            y: { a: 'リャ', i: 'リィ', u: 'リュ', e: 'リェ', o: 'リョ' },
        },
        w: { a: 'ワ', i: 'ウィ', u: 'ウ', e: 'ウェ', o: 'ヲ' },
        g: {
            a: 'ガ', i: 'ギ', u: 'グ', e: 'ゲ', o: 'ゴ',
            y: { a: 'ギャ', i: 'ギィ', u: 'ギュ', e: 'ギェ', o: 'ギョ' },
        },
        z: {
            a: 'ザ', i: 'ジ', u: 'ズ', e: 'ゼ', o: 'ゾ',
            y: { a: 'ジャ', i: 'ジィ', u: 'ジュ', e: 'ジェ', o: 'ジョ' },
        },
        j: {
            a: 'ジャ', i: 'ジ', u: 'ジュ', e: 'ジェ', o: 'ジョ',
            y: { a: 'ジャ', i: 'ジィ', u: 'ジュ', e: 'ジェ', o: 'ジョ' },
        },
        d: {
            a: 'ダ', i: 'ヂ', u: 'ヅ', e: 'デ', o: 'ド',
            h: { a: 'デャ', i: 'ディ', u: 'デュ', e: 'デェ', o: 'デョ' },
            y: { a: 'ヂャ', i: 'ヂィ', u: 'ヂュ', e: 'ヂェ', o: 'ヂョ' },
        },
        b: {
            a: 'バ', i: 'ビ', u: 'ブ', e: 'ベ', o: 'ボ',
            y: { a: 'ビャ', i: 'ビィ', u: 'ビュ', e: 'ビェ', o: 'ビョ' },
        },
        v: {
            a: 'ヴァ', i: 'ヴィ', u: 'ヴ', e: 'ヴェ', o: 'ヴォ',
            y: { a: 'ヴャ', i: 'ヴィ', u: 'ヴュ', e: 'ヴェ', o: 'ヴョ' },
        },
        p: {
            a: 'パ', i: 'ピ', u: 'プ', e: 'ペ', o: 'ポ',
            y: { a: 'ピャ', i: 'ピィ', u: 'ピュ', e: 'ピェ', o: 'ピョ' },
        },
        x: {
            a: 'ァ', i: 'ィ', u: 'ゥ', e: 'ェ', o: 'ォ',
            y: {
                a: 'ャ', i: 'ィ', u: 'ュ', e: 'ェ', o: 'ョ',
            },
            t: {
                u: 'ッ',
                s: {
                    u: 'ッ',
                },
            },
        },
        l: {
            a: 'ァ', i: 'ィ', u: 'ゥ', e: 'ェ', o: 'ォ',
            y: {
                a: 'ャ', i: 'ィ', u: 'ュ', e: 'ェ', o: 'ョ',
            },
            t: {
                u: 'ッ',
                s: {
                    u: 'ッ',
                },
            },
        },
    }
}

export default searchConfig